// auth.effects.ts
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { setToken } from './auth.action';
import { tap } from 'rxjs/operators';

@Injectable()
export class AuthEffects {
  setToken$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(setToken),
        tap(action => {
          // Stockez le token dans le localStorage ou sessionStorage ici si nécessaire
          sessionStorage.setItem('token', action.token);
        })
      ),
    { dispatch: false }
  );

  constructor(private actions$: Actions) {}
}
