// background.guard.ts

import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, NavigationEnd } from '@angular/router';
import { BackgroundService } from './background.service';

@Injectable({
  providedIn: 'root'
})
export class BackgroundGuard implements CanActivate {

  constructor(private router: Router, private backgroundService: BackgroundService) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        // Mettre à jour l'URL de l'image de fond en fonction de la route actuelle
        const currentRoute = state.url;
        let backgroundImageUrl = 'default-image-url.jpg'; // URL de l'image de fond par défaut pour les autres pages

        if (currentRoute === '/authentification/enregistrement') {
            console.log('Nous sommes dans la page 2')
            backgroundImageUrl = 'page2-image-url.jpg';
            this.backgroundService.setBackgroundImageUrl(backgroundImageUrl);
            // Définir l'URL de l'image de fond pour la page1
        } else if (currentRoute === '/authentification/connexion') {
            console.log('Nous sommes dans la page 1')
            backgroundImageUrl = 'page1-image-url.jpg';
            this.backgroundService.setBackgroundImageUrl(backgroundImageUrl);
            // Définir l'URL de l'image de fond pour la page2
        } else{
            console.log('Nous sommes dans la page autre')
            backgroundImageUrl = 'page-autre-image-url.jpg'; 
            this.backgroundService.setBackgroundImageUrl(backgroundImageUrl);
            // Définir l'URL de l'image de fond pour la page2
        }// Ajouter d'autres conditions pour les autres pages
      }
    });

    return true;
  }
}
