import { MenuItem } from './menu.model';

export const menuAdminCda: MenuItem[] = [

    {
        id: 5,
        label: 'Gestion Utilisateurs',
        icon: 'users',
        link: '/configuration/gestion-utilisateurs/liste',
    }
];

export const menuOperateur: MenuItem[] = [

    {
        id: 1,
        label: 'Tableau de bord',
        icon: 'dashboard',
        link: '/tableau-de-bord',
    },
    {
        id: 2,
        label: 'Fiches d\'empotage',
        icon: 'fiche_empotage',
        link: '/fiche-empotage',
    },
    {
        id: 3,
        label: 'Demandes de rectifs',
        icon: 'rectif',
        link: '/demande-rectif',
    },
    {
        id: 4,
        label: 'Gestion des RDV',
        icon: 'rdv',
        link: '/gestion-rdv',
    }
];

export const menuAgentDouanes: MenuItem[] = [

    {
        id: 1,
        label: 'Tableau de bord',
        icon: 'dashboard',
        link: '/tableau-de-bord',
    },
    {
        id: 2,
        label: 'Fiches d\'empotage',
        icon: 'fiche_empotage',
        link: '/fiche-empotage',
    },
    {
        id: 3,
        label: 'Demandes de rectifs',
        icon: 'rectif',
        link: '/demande-rectif',
    },
    {
        id: 4,
        label: 'Gestion des RDV',
        icon: 'rdv',
        link: '/gestion-rdv',
    }
];

export const menuCbDouanes: MenuItem[] = [

    {
        id: 1,
        label: 'Tableau de bord',
        icon: 'dashboard',
        link: '/tableau-de-bord',
    },
    {
        id: 2,
        label: 'Fiches d\'empotage',
        icon: 'fiche_empotage',
        link: '/fiche-empotage',
    },
    {
        id: 3,
        label: 'Demandes de rectifs',
        icon: 'rectif',
        link: '/demande-rectif',
    },
    {
        id: 4,
        label: 'Gestion des RDV',
        icon: 'rdv',
        link: '/gestion-rdv',
    },
    {
        id: 5,
        label: 'Gestion Utilisateurs',
        icon: 'users',
        link: '/configuration/gestion-utilisateurs/liste',
    }/* ,
    {
        id: 6,
        label: 'Gestion des services',
        icon: 'services',
        link: '/configuration/gestion-services/liste',
    } */
];

export const menuAssistanceDouanes: MenuItem[] = [
    {
        id: 2,
        label: 'Fiches d\'empotage',
        icon: 'fiche_empotage',
        link: '/fiche-empotage',
    }
];

export const menuAdminDouanes: MenuItem[] = [
    {
        id: 2,
        label: 'Fiches d\'empotage',
        icon: 'fiche_empotage',
        link: '/fiche-empotage',
    },
    {
        id: 5,
        label: 'Gestion Utilisateurs',
        icon: 'users',
        link: '/configuration/gestion-utilisateurs/liste',
    }
];