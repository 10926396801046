// background.service.ts

import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class BackgroundService {
  private backgroundImageUrl = 'default-image-url.jpg'; // URL de l'image de fond par défaut

  getBackgroundImageUrl(): string {
    return this.backgroundImageUrl;
  }

  setBackgroundImageUrl(url: string): void {
    this.backgroundImageUrl = url;
  }
}
