import { Component, Renderer2, ElementRef, OnInit } from '@angular/core';
import { BackgroundService } from './services/background.service';
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit  {

  constructor( private elementRef: ElementRef, private backgroundService: BackgroundService) { }

  ngOnInit(): void {
    // // Mettre à jour l'image de fond lors du chargement initial de l'application
   }
}
